import React, { FunctionComponent } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "../Link";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface, PortableText, SanityColorList, Slug } from "../../types/SanityTypes";
import { ColorVariations } from "../../types/ComponentTypes";
import "./styles.scss";

export interface ArticleDoubleTeaserInterface {
  ctaReference: {
    _id: string;
    teaser?: {
      teaserHeadline: string;
      teaserCTALabel: string;
      _rawTeaserText: PortableText;
      _rawTeaserSecondaryImage: ImageInterface;
      _rawTeaserHeroImage: ImageInterface;
      // For Preview
      teaserText?: PortableText;
      teaserSecondaryImage?: ImageInterface;
      teaserHeroImage?: ImageInterface;
    };
    slug?: {
      current: string;
    };
    parentSection?: Slug & {
      main: {
        sectionColor: SanityColorList;
      };
      parentSection?: Slug & {
        main: {
          sectionColor: SanityColorList;
        };
      };
    };
  };
  articleDoubleTeaserBlockType: {
    name: string;
  };
  textAlignment: string;
}

const ArticleDoubleTeaser: FunctionComponent<ArticleDoubleTeaserInterface> = ({
  ctaReference,
  articleDoubleTeaserBlockType,
  textAlignment
}) => {
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const colorVariations: ColorVariations = {
    Purple: {
      primaryGradientColor: "var(--purple-light-color)",
      secondaryGradientColor: "var(--pink-light-color)",
      gradientDegrees: "10"
    },
    Red: {
      primaryGradientColor: "var(--orange-color)",
      secondaryGradientColor: "var(--pink-medium-color)",
      gradientDegrees: "300"
    },
    Teal: {
      primaryGradientColor: "var(--get-mentored-green-color)",
      secondaryGradientColor: "var(--get-mentored-blue-color)",
      gradientDegrees: "225"
    },
    Pink: {
      primaryGradientColor: "var(--pink-bright-color)",
      secondaryGradientColor: "var(--pink-bright-color)",
      gradientDegrees: "-146"
    },
    Blue: {
      primaryGradientColor: "var(--blue-dark-color)",
      secondaryGradientColor: "var(--blue-bright-color)",
      gradientDegrees: "90"
    },
    Primary: {
      primaryGradientColor: "var(--primary-color)",
      secondaryGradientColor: "var(--primary-color)",
      gradientDegrees: "90"
    }
  };

  const parentAttributes = ctaReference.parentSection?.parentSection
    ? {
        colorTheme: ctaReference.parentSection.parentSection.main?.sectionColor.title as string,
        colorThemeValue: ctaReference.parentSection.parentSection.main?.sectionColor.value,
        parentPath: `${ctaReference.parentSection.parentSection.slug.current}/${ctaReference.parentSection?.slug.current}`
      }
    : {
        colorTheme: ctaReference.parentSection?.main?.sectionColor.title as string,
        colorThemeValue: ctaReference.parentSection?.main?.sectionColor.value,
        parentPath: ctaReference.parentSection?.slug.current
      };

  const heroImage = ctaReference.teaser?._rawTeaserHeroImage || ctaReference.teaser?.teaserHeroImage;
  const secondaryImage = ctaReference.teaser?._rawTeaserSecondaryImage || ctaReference.teaser?.teaserSecondaryImage;
  const text = ctaReference.teaser?._rawTeaserText || ctaReference.teaser?.teaserText;

  const renderGradient = `linear-gradient(${colorVariations[parentAttributes.colorTheme]?.gradientDegrees}deg, ${
    colorVariations[parentAttributes.colorTheme]?.primaryGradientColor
  } 0%, ${colorVariations[parentAttributes.colorTheme]?.secondaryGradientColor} 94%)`;

  const headingRule = () => {
    const splitHeading = ctaReference.teaser?.teaserHeadline.split(" ");
    if (splitHeading?.length === 3) {
      return (
        <h2 style={{ color: parentAttributes.colorThemeValue }} data-testid="split-heading">
          {splitHeading[0]}
          <br />
          {splitHeading[1]} {splitHeading[2]}
        </h2>
      );
    } else {
      return <h2 style={{ color: parentAttributes.colorThemeValue }}> {ctaReference.teaser?.teaserHeadline} </h2>;
    }
  };

  const HeadlineTop = (
    <Row className="large-headline">
      <Col>
        <div className="box1">
          {headingRule()}
          {text && <BlockContent blocks={text} />}
          <Link
            _id={ctaReference._id}
            to={`${parentAttributes.parentPath}/${ctaReference.slug?.current}`}
            className="btn-primary"
            data-analytics-event4=""
          >
            {ctaReference.teaser?.teaserCTALabel}
          </Link>
        </div>

        <div className="box2">
          {heroImage ? (
            <picture
              data-testid="secondary-image"
              className="placeholder"
              style={{
                paddingTop: `calc(31% / ${heroImage.asset.metadata?.dimensions.aspectRatio})`,
                paddingBottom: `calc(32% / ${heroImage.asset.metadata?.dimensions.aspectRatio})`,
                background: `url(${heroImage.asset.metadata?.lqip})`,
                backgroundSize: "cover"
              }}
            >
              <source
                media={"(min-width: 1200px)"}
                srcSet={
                  urlBuilder
                    .image(heroImage)
                    .auto("format")
                    .quality(80)
                    .width(800)
                    .height(800)
                    .format("webp")
                    .url() as string
                }
              />
              <source
                media={"(min-width: 768px)"}
                srcSet={
                  urlBuilder
                    .image(heroImage)
                    .auto("format")
                    .quality(80)
                    .width(500)
                    .height(500)
                    .format("webp")
                    .url() as string
                }
              />
              <img
                className="img-fluid secondary-image"
                src={
                  urlBuilder
                    .image(heroImage)
                    .auto("format")
                    .quality(80)
                    .width(400)
                    .height(400)
                    .format("webp")
                    .url() as string
                }
                alt={heroImage.alt}
                loading={"lazy"}
              />
            </picture>
          ) : (
            <div style={{ background: renderGradient, width: "400px", height: "400px" }} />
          )}
        </div>

        <div className="box3">
          {secondaryImage ? (
            <picture
              data-testid="primary-image"
              className="placeholder"
              style={{
                background: `url(${secondaryImage.asset.metadata?.lqip})`,
                backgroundSize: "scale-down"
              }}
            >
              <img
                className="img-fluid"
                src={
                  urlBuilder
                    .image(secondaryImage)
                    .auto("format")
                    .quality(80)
                    .width(600)
                    .height(1200)
                    .format("webp")
                    .url() as string
                }
                alt={secondaryImage.alt}
                loading={"lazy"}
              />
            </picture>
          ) : (
            <div style={{ background: renderGradient, width: "400px", height: "700px" }} />
          )}
        </div>
      </Col>
    </Row>
  );

  const HeadlineCentre = (
    <div className="headline-centre">
      <Row>
        <Col>
          {secondaryImage ? (
            <div className="first-image-position">
              <picture
                data-testid="primary-image"
                className="placeholder"
                style={{
                  paddingTop: `calc(45% / ${secondaryImage.asset.metadata?.dimensions.aspectRatio})`,
                  paddingBottom: `calc(45% / ${secondaryImage.asset.metadata?.dimensions.aspectRatio})`,
                  background: `url(${secondaryImage.asset.metadata?.lqip})`,
                  backgroundSize: "scale-down"
                }}
              >
                <img
                  className="img-fluid"
                  src={
                    urlBuilder
                      .image(secondaryImage)
                      .auto("format")
                      .quality(80)
                      .width(600)
                      .height(1300)
                      .format("webp")
                      .url() as string
                  }
                  alt={secondaryImage.alt}
                  loading={"lazy"}
                />
              </picture>
            </div>
          ) : (
            <div style={{ background: renderGradient, width: "400px", height: "700px" }} />
          )}

          <div className="second-image-position">
            {heroImage ? (
              <picture
                data-testid="secondary-image"
                style={{
                  paddingTop: `calc(25% / ${heroImage.asset.metadata?.dimensions.aspectRatio})`,
                  paddingBottom: `calc(15% / ${heroImage.asset.metadata?.dimensions.aspectRatio})`,
                  background: `url(${heroImage.asset.metadata?.lqip})`,
                  backgroundSize: "cover"
                }}
              >
                <source
                  media={"(min-width: 1200px)"}
                  srcSet={
                    urlBuilder
                      .image(heroImage)
                      .auto("format")
                      .quality(80)
                      .width(1000)
                      .height(800)
                      .format("webp")
                      .url() as string
                  }
                />
                <source
                  media={"(min-width: 768px)"}
                  srcSet={
                    urlBuilder
                      .image(heroImage)
                      .auto("format")
                      .quality(80)
                      .width(800)
                      .height(600)
                      .format("webp")
                      .url() as string
                  }
                />
                <img
                  className="img-fluid"
                  src={
                    urlBuilder
                      .image(heroImage)
                      .auto("format")
                      .quality(80)
                      .width(500)
                      .height(400)
                      .format("webp")
                      .url() as string
                  }
                  alt={heroImage.alt}
                  loading={"lazy"}
                />
              </picture>
            ) : (
              <div style={{ background: renderGradient, width: "500px", height: "400px" }} />
            )}
            <div className="text-content">
              <h2 style={{ color: parentAttributes.colorThemeValue }}>{ctaReference.teaser?.teaserHeadline}</h2>
              {text && <BlockContent blocks={text} />}
              <Link
                _id={ctaReference._id}
                to={`${parentAttributes.parentPath}/${ctaReference.slug?.current}`}
                className="btn-primary"
              >
                {ctaReference.teaser?.teaserCTALabel}
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

  const renderDoubleTeaser = () => {
    if (textAlignment === "Top" && articleDoubleTeaserBlockType.name === "Split Image") return HeadlineTop;
    if (textAlignment === "Centre" && articleDoubleTeaserBlockType.name === "Split Image") return HeadlineCentre;
  };

  return (
    <section data-testid="article-double-teaser" className="article-double-teaser">
      <Container fluid>{renderDoubleTeaser()}</Container>
    </section>
  );
};

export default ArticleDoubleTeaser;
